@import "../../../Common/styles/componentes.scss";


.ws-filter{
    width: 427px;
    height: 38px;
    box-shadow: inset 0 -1px 0 0 #afafaf;
    background-color: #f5f7f9;
    border: none;    
    border-left: 12px solid #53a9ff;
    padding-left: 15px;
    padding-right: 42px;
    @extend .text-black-14;

    &.full{
        background-color: white; 
    }

    &::placeholder {
        color: #afb1bc;
        font-size: 13.5px;
    }

    &:hover{
        border: 1px solid #afb1bc;     
        border-left: 12px solid #53a9ff;  
    }

    &:focus{
        background-color: white;        

        &::placeholder {
            color: transparent;
        }
    }
}

.input-chevron-icon {
    font-size: 18px;
    position: relative;
    right: 28px;
}

.data-item{
    &:hover{
        background-color: #f1f4f7;
        font-weight: 600;
    }
}